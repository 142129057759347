import cat1 from './categories-layout/cat1'
import DigitalService from './categories-layout/DigitalService'

import Artificialintelligence from './categories-layout/Artificialintelligence'
import TechnologySolutions from './categories-layout/TechnologySolutions'
import ResearchandDevelopment from './categories-layout/ResearchandDevelopment'
import CloudServies from './categories-layout/CloudServies'
import BusinessOperations from './categories-layout/BusinessOperations'
import AllService from './categories-layout/AllService'
import RegulatoryAffairs from './categories-layout/RegulatoryAffairs'
import InternationalRA from './categories-layout/InternationalRA'
import RegulatoryOperations from './categories-layout/RegulatoryOperations'
import DocProcessing from './categories-layout/DocProcessing'
import AdPromo from './categories-layout/AdPromo'
import Labeling from './categories-layout/Labeling'
import Compliance from './categories-layout/Compliance'
import CMC from './categories-layout/CMC'
import Azure from './categories-layout/Azure'
import ApplicationModernization from './categories-layout/ApplicationModernization'
import icrosoft365 from './categories-layout/icrosoft365'
import Dynamics365 from './categories-layout/Dynamics365'
import IntegrationServices from './categories-layout/IntegrationServices'
import ManagedServices from './categories-layout/ManagedServices'
import TestingServices from './categories-layout/TestingServices'
import Healthcare from './categories-layout/Healthcare'
import MedicalScience from './categories-layout/MedicalScience'
import Insurance from './categories-layout/Insurance'
import Telecoms from './categories-layout/Telecoms'
import Energy_Utilities from './categories-layout/Energy_Utilities'
import PublicSector from './categories-layout/PublicSector'
import Banking from './categories-layout/Banking'
import Electronics from './categories-layout/Electronics'
import Media_Entertainment from './categories-layout/Media_Entertainment'
import Manufacturing from './categories-layout/Manufacturing'
import Retail from './categories-layout/Retail'
import All_Industrie from './categories-layout/All_Industrie'

export async function getLayout(file) {
// import // from './categories-layout///' return import("./categories-layout/" + file)
    // if(file==="Labeling")
    // {
    //     console.log("lkj",filename)
    // }
    // return filename
    // try {
    // }
    // catch (e) {
        // let fs=require("fs")
        // fs.writeFileSync("./categorylayout/"+file+".js")
        // let result=await fetch("http://localhost:4000/?name="+file)        
        // console.log("created a file in src/layouts/categorylayout/"+file)
        // return DigitalService
    // }
}
const a=[
    { name: "cat1", layout: cat1 },
    { name: "DigitalService", layout: DigitalService },
    { name: "Artificialintelligence", layout: Artificialintelligence },
    { name: "TechnologySolutions", layout: TechnologySolutions },
    { name: "ResearchandDevelopment", layout: ResearchandDevelopment },
    { name: "CloudServies", layout: CloudServies },
    { name: "DigitalService", layout: DigitalService },
    { name: "BusinessOperations", layout: BusinessOperations },
    { name: "AllService", layout: AllService },
    { name: "RegulatoryAffairs", layout: RegulatoryAffairs },
    { name: "InternationalRA", layout: InternationalRA },
    { name: "RegulatoryOperations", layout: RegulatoryOperations },
    { name: "DocProcessing", layout: DocProcessing },
    { name: "AdPromo", layout: AdPromo },
    { name: "Labeling", layout: Labeling },
    { name: "Compliance", layout: Compliance },
    { name: "CMC", layout: CMC },
    { name: "Azure", layout: Azure },
    { name: "ApplicationModernization", layout: ApplicationModernization },
    { name: "Artificialintelligence", layout: Artificialintelligence },
    { name: "icrosoft365", layout: icrosoft365 },
    { name: "Dynamics365", layout: Dynamics365 },
    { name: "IntegrationServices", layout: IntegrationServices },
    { name: "ManagedServices", layout: ManagedServices },
    { name: "TestingServices", layout: TestingServices },
    { name: "healthcare", layout: Healthcare },
    { name: "MedicalScience", layout: MedicalScience },
    { name: "Insurance", layout: Insurance },
    { name: "Telecoms", layout: Telecoms },
    { name: "Energy_Utilities", layout: Energy_Utilities },
    { name: "PublicSector", layout: PublicSector },
    { name: "Banking", layout: Banking },
    { name: "Electronics", layout: Electronics },
    { name: "Media_Entertainment", layout: Media_Entertainment },
    { name: "Manufacturing", layout: Manufacturing },
    { name: "Retail", layout: Retail },
    { name: "All_Industrie", layout: All_Industrie },
]

export default a 