import homelayout from './homelayout'
import pagelayout from './pagelayout'
import categorylayout from './categorylayout'
import bloglayout from './blogslayout/'
import singlelayout from './blogslayout/singlelayout'
const a= {
    "home": homelayout,
    "page":pagelayout,
    "category":categorylayout,
    "blogs":bloglayout,
    "single":singlelayout,
}
export default a 