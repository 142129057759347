const a= {
    name: "contact", components: [
        {
            component: "banner", data: {
                title: "CONTACT US",
                description: "We'd Love to hear from you",
                // image: "/businesssectionconfig/map.jpg"
                image: "/img/map.jpg"
               
            }
        },
        {
            component: "cards2", data: {
                title:"Find our office",
                data: [
                    {
                        id: 1, location: `Saitpoint Limited HEADQUARTERS UK`, address: "G24, Westlink House, 981 Great West Road, Brentford, England, TW8 9DN, Contact us:info@saitpoint.com", image: "/img/uk.jpg", to: "https://goo.gl/maps/BCjFmW2Atwx8f2pm9"
                    },
                    {
                        id: 3, location: `Saitpoint Limited, India`, address: `Hitech City Rd, Jaihind Enclave, Madhapur, Telangana, India - 500033`, image: "/img/india.jpg", to: "https://goo.gl/maps/gP2HBJMzoVpKod5cA"
                    },
                    {
                        id: 2, location: `Saitpoint Limited, USA`, address: "Terra Cotta Cir, Herndon, VA 20171, Contact Us:info@saitpoint.com", image: "/img/usa.jpg", to: "https://goo.gl/maps/5yT3p491eGK5nmQNA"
                    },

                ]
            }
        },
        {
            component: "form1", data: {
                validation: false, data: {
                    fields: [
                        { type: "text", name: "fn", label: "first name", validation: `` },
                        { type: "text", name: "ln", label: "last name", validation: `` },
                        { type: "text", name: "email", label: "email", validation: `` },
                        { type: "text", name: "phone", label: "phone number", validation: `` },
                        { type: "text", name: "country", label: "country", validation: `` },
                        { type: "textarea", name: "message", label: "type of comments", validation: `` },
                    ],
                    submitLabel: "Submit",
                    submitAction: "",
                    title: "LET'S TALK",
                    desc: "We'd like to hear from you. Please fill out the form below and we'll be in touch.",
                }
            }
        },
        {
            component: "partners", data: {
                pagination: false, data: [
                    { id: 1, image: "/img/micro.png", },
                    { id: 2, image: "/businesssectionconfig/sp.jpg", },
                ]
            }
        },

    ]
}
export default a 