import React from "react";
import Layout from './Layout'
export default function Corporate(props) {
    let data = {
        component: "slider3", data: [
            { title: "Saitpoint Webinars", heading: "Learn with our webinar series", image: "/businesssectionconfig/Hadoop.png", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
            { title: "Saitpoint Event", heading: "Focus Event", image: "/businesssectionconfig/Event2.jpg", p: "Organisation Event", date: "24-21-11" },
            { title: "Saitpoint Budget", heading: "Annual Report for the Saitpoint", image: "/businesssectionconfig/event8.jpg", p: "Budget Planning", date: "24-21-11" },
        ]
    }
    return (
        <div className="wrapper corporate">
            <h1>Corporate Social Responsibility and Purpose-Driven</h1>
            <p>
                Being a Purpose-Driven organization, WinWire affirms t=he commitment to the communities where we work and live and is proud to make a difference by giving back to the community. We help customers leverage technology to address society’s challenges and help build a more sustainable world and increase our positive social impact. Our recent support includes:
            </p>
            <div className="flex">
                <div className="left">
                    <ul>
                        <li>Supports music education through Inspire India Project – A Shankar Mahadevan Initiative to provide music for underprivileged children.</li>
                        <li>Supports music education through Inspire India Project – A Shankar Mahadevan Initiative to provide music for underprivileged children.</li>
                        <li>Supports music education through Inspire India Project – A Shankar Mahadevan Initiative to provide music for underprivileged children.</li>
                        <li>Supports music education through Inspire India Project – A Shankar Mahadevan Initiative to provide music for underprivileged children.</li>
                        <li>Supports music education through Inspire India Project – A Shankar Mahadevan Initiative to provide music for underprivileged children.</li>
                        <li>Supports music education through Inspire India Project – A Shankar Mahadevan Initiative to provide music for underprivileged children.</li>
                        <li>Supports music education through Inspire India Project – A Shankar Mahadevan Initiative to provide music for underprivileged children.</li>
                    </ul>
                </div>
                <div className="right">
                    <Layout {...data} />
                </div>
            </div>
        </div>
    );
}