import React  from 'react';
export default function Speak({ data }) {
    return (
        <div className='speak' style={{ background: `url('${data.backgroundImage}')` }}>
            <div className='content'>
                {data.title && <h2>{data.title}</h2>}
                {/* {data.video && <iframe width="1060" height="315" src="https://www.youtube.com/embed/KXkBZCe699A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>} */}
                {data.url && <video poster='/logo.png' src={data.url} autoPlay controls  width={760} height={315}></video>}
            </div>
        </div>
    );
}