const a= [
            
    {
        component: "cards3", data: {
            caption: "Artificial Intelligence",
            pagination:true,
            data:[
                { id: 1, title: "Artificial Intelligence", tags: "Artificial Intelligence", image: "/img/ai.jpg", desc: 'Artificial Intelligence (AI) has crossed the threshold of pilots and entered the wider market. Performing AI, Saitpoint’s comprehensive portfolio of AI & Analytics services provides your organization with the ability to leverage the full transformative power of Data & AI at scale. By activating data and insights at the heart of your business in your everyday decisions and actions, you will augment your organization’s intelligence and amplify the business outcomes you expect from Data & AI.arket. Our teams have a proven track record of delivering tangible business outcomes globally and at scale, across industries and sectors', link: "https://www.google.co.uk/", hasicon: true, icon: "" },
                { id: 2, title: "Client stories", tags: "Client stories", image: "/sectionconfig/client stories.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 3, title: "Meet our people", tags: "Meet our people", image: "/sectionconfig/meetourpeople.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 4, title: "Case studies & stories", tags: "Case studies & stories", image: "/sectionconfig/case.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 5, title: "Cloud Journey", tags: "Cloud Journey", image: "/sectionconfig/cloud journey.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 6, title: "my-caption", tags: "Voices of Change", image: "/sectionconfig/voices.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 7, title: "Capabilities", tags: "Capabilities", image: "/sectionconfig/cap.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 8, title: "Our Operations", tags: "What We Do", image: "/sectionconfig/whatwedo.jpg", desc: 'IT product and solutions company for the Utility, Telecom and Public sector', link: "/", hasicon: false, icon: "" },
                { id: 9, title: "Application", tags: "Blogs", image: "/sectionconfig/blog.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
            ]
        }
    },
    {
        component: "partners", data: {
            data: [
                { id: 1, image: "https://www.winwire.com/wp-content/uploads/2017/12/micro.png", },
                { id: 2, image: "/businesssectionconfig/sp.jpg", },
            ]
        }
    },
    ]


        export default a 