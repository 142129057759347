import React from 'react'
export default function cards2(props) {
    let { data } = props.data
    return (
        <div className='cards2-container'>
            <h1 align="center">{props.data.title}</h1>
            <div className='cards2'>
                {data.map((item, index) => <div className='cards2-item' style={{ backgroundImage: `url('${item.image}')` }}>
                    <div className='shadow'>
                        <h3>{item.location}</h3>
                        <h5>{item.address}</h5>
                        <a rel="noreferrer" href={item.to} target={"_blank"} className="cards2-btn">Locate Us</a>
                    </div>
                </div>)}
            </div>
        </div>
    )
}
