import React, { useState } from 'react'
import { Link } from 'react-router-dom'
export default function Top() {
    const [state, setstate] = useState('')
    const handlekeyup = e => {
        // console.log(e)
        setstate(e.target.value)
    }
    const handleSeacrh = () => {
        window.open("https://www.google.com/search?q=" + state)
    }
    return (
        <div className='top-menu wrapper'>
            <div className='item'>
                <Link to="/"><img alt="best in country saitpoint.com"  src="/logo.jpg" /></Link>
            </div>
            <div className='item'>
                <div className='search'>
                    <input onKeyUp={handlekeyup} />
                    <i onClick={handleSeacrh} title="Search" className='fa fa-search'></i>
                </div>
            </div>
            <div className='item'>
                <Link to="/"><i title="home" className='fa icon-circle fa-home'></i></Link>
                <Link to="/page/ourcompany"><i title="information" className='fa icon-circle fa-info-circle'></i></Link>
                {/* <Link to="/about"><i title="about" className='fa icon-circle fas fa-address-card'></i></Link> */}
                <Link to="/page/notifications"><i title="notifications" className='fa icon-circle fa-bell'></i></Link>
                <Link to="/blogs"><i title="blog" className='fa icon-circle fa-rss'></i></Link>
                <Link to="/page/contact"><i title="contact" className='fa icon-circle fa-envelope'></i></Link>
            </div>
        </div>
    )
}
