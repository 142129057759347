 const a=[
    {
        "item": { "path": "/", "label": "Services" },
        "children": [
            { "path": "/category/Artificialintelligence", "label": "Artificial intelligence" },
            { "path": "/category/TechnologySolutions", "label": "Technology Solutions" },
            { "path": "/category/ResearchandDevelopment", "label": "Research and Development" },
            { "path": "/category/CloudServies", "label": "Cloud Servies" },
            { "path": "/category/DigitalService", "label": "Digital Service" },
            { "path": "/category/BusinessOperations", "label": "Business Operations" },
            { "path": "/category/AllService", "label": "All Services" }
        ]
    },
    {
        "item": { "path": "/", "label": "Clinical Operations" },
        "children": [
            { "path": "/category/RegulatoryAffairs", "label": "Regulatory Affairs" },
            { "path": "/category/InternationalRA", "label": "International RA" },
            { "path": "/category/RegulatoryOperations", "label": "Regulatory Operations" },
            { "path": "/category/DocProcessing", "label": "Doc Processing" },
            { "path": "/category/AdPromo", "label": "Ad Promo" },
            { "path": "/category/Labeling", "label": "Labeling" },
            { "path": "/category/Compliance", "label": "Compliance" },
            { "path": "/category/CMC", "label": "CMC" },
        ]
    },
    {
        "item": { "path": "/", "label": "Expertise" },
        "children": [
            { "path": "/category/Azure", "label": "Azure" },
            { "path": "/category/ApplicationModernization", "label": "Application Modernization" },
            { "path": "/category/Artificialintelligence", "label": "Artificial intelligence" },
            { "path": "/category/icrosoft365", "label": "Microsoft 365" },
            { "path": "/category/Dynamics365", "label": "Dynamics 365" },
            { "path": "/category/IntegrationServices", "label": "Integration Services" },
            { "path": "/category/ManagedServices", "label": "Managed Services" },
            { "path": "/category/TestingServices", "label": "Testing Services" },
        ]
    },
    {
        "item": { "path": "/", "label": "Industries" },
        "children": [
            { "path": "/category/Healthcare", "label": "Healthcare" },
            { "path": "/category/MedicalScience", "label": "Medical Science" },
            { "path": "/category/Insurance", "label": "Insurance" },
            { "path": "/category/Telecoms", "label": "Telecoms" },
            { "path": "/category/Energy_Utilities", "label": "Energy & Utilities" },
            { "path": "/category/PublicSector", "label": "Public Sector" },
            { "path": "/category/Banking", "label": "Banking" },
            { "path": "/category/Electronics", "label": "Electronics" },
            { "path": "/category/Media_Entertainment", "label": "Media & Entertainment" },
            { "path": "/category/Manufacturing", "label": "Manufacturing" },
            { "path": "/category/Retail", "label": "Retail" },
            { "path": "/category/All_Industrie", "label": "All Industries" }
        ]
    },
    {
        "item": { "path": "/", "label": "Partners" },
        "children": [
            { "path": "https://www.microsoft.com/", "label": "Microsoft" },
            { "path": "https://aws.amazon.com/", "label": "Amazon Web Services" },
            { "path": "https://cloud.google.com/", "label": "Google" },
            { "path": "https://www.myworld.com/", "label": "myWorld" },
            { "path": "https://www.servicenow.com/", "label": "ServiceNow" },
            { "path": "/", "label": "All our partners" }
        ]
    },
    {
        "item": { "path": "/", "label": "About us" },
        "children": [
            { "path": "/page/Celebrating", "label": "Celebrating 10 Years" },
            { "path": "/page/ourcompany", "label": "Our Company" },
            { "path": "/page/purpose", "label": "Our Purpose" },
            { "path": "/page/ourcompany", "label": "Our Offices" },
            { "path": "/page/contact", "label": "Contact us" }
        ]
    },
    {
        "item": { "path": "/", "label": "Careers" },
        "children": [
            { "path": "/page/Jobs", "label": "Jobs" },
            { "path": "/page/Learning", "label": "Learning & Development" },
            { "path": "/page/Diversity", "label": "Diversity & Inclusion" },
            { "path": "/page/Employee", "label": "Employee stories" },
        ]
    },
]
export default a