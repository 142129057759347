import React, { useState } from 'react'
import config from '../config'
const RenderItem = ({x}) => {
    if (x.children) {
        return <div className="dropdown">
            <button className="dropbtn">{x.item.label} {" "}
                <i className="fa fa-caret-down"></i>
            </button>
            <div className="dropdown-content">
                {x.children.map((item,index)=><a key={index} href={item.path}>{item.label}</a>)}
            </div>
        </div>
    }

    return <a rel="noreferrer" href={x.item.label} className="active">
        {x.item.label}
    </a>
}
export default function Header() {
    const [status, setstatus] = useState(true)
    const handleClick = () => {
        setstatus(!status)
    }
    return (
        <div className='header'>
            <div id="myTopnav" className={status ? "topnav" : "topnav responsive"}>
                {config.header.map((parent, parentindex) => <RenderItem key={parentindex} x={parent}/>)}
                <span className="icon" onClick={handleClick}>&#9776;</span>
            </div>
        </div>
    )
}
