import React  from 'react';

import { Link } from 'react-router-dom';
export default function Ready(props) {
    let {title,label,to}=props.data
    return (
        <div className='ready'>
            <h1>{title}</h1>
            <Link to={to}>{label}</Link>
        </div>
    );
}