const a=[
    {
        component: "slider1", data: [
            {page_format:"carousel",to:"/page/landing", id: 1, title: "Home Page", description: "Saitpoint Limited", image: "/carousel/landingpage.jpg" },
            {page_format:"carousel",to:"/page/learning", id: 2, title: "Learning", description: "Henacing skills", image: "/carousel/learning.jpg" },
            {page_format:"carousel",to:"/page/cloud", id: 3, title: "The Cloud Journey", description: "the cloud transformation", image: "/carousel/cjourney.jpg" },
            {page_format:"carousel",to:"/page/medical", id: 4, title: "Medical Science", description: "Healthcare consulting professional ", image: "/carousel/medical.jpg" },
            {page_format:"carousel",to:"/page/10years", id: 5, title: "Agricultural", description: "Plant and Animals growth ", image: "/carousel/sp.jpg" },
        ]
    },
    {
        component: "services", data: {
            title: "10+ years of successful stories on achieving our mission to make your business better",
            data: [{ id: 1, image: "/homepageabout/healthcare.jpg", title: "Healthcare", href: "/category/healthcare" },
            { id: 2, image: "/homepageabout/govern.jpg", title: "Public Sector", href: "/category/2" },
            { id: 3, image: "/homepageabout/medical-service-logos.jpg", title: "Medical Science", href: "/category/3" },
            { id: 4, image: "/homepageabout/e&u.jpg", title: "Energy Utilities", href: "/category/4" },
            { id: 5, image: "/homepageabout/retail.jpg", title: "Retail", href: "/category/5" },
            { id: 6, image: "/homepageabout/all.jpg", title: "All Industries", href: "/category/6" },]
        }
    },
    {
        component: "business", data: {
            title: "Business Transformation with Microsoft Cloud Solutions",
            data: [
                { id: 1, to: "/category/1", image: "/businesssectionconfig/azure.jpg", title: "Azure", description: "From insights to innovation, transform your business with secure Microsoft Azure" },
                { id: 2, to: "/category/1", image: "/businesssectionconfig/365.jpg", title: "Microsoft 365", description: "Empower your teams be more collaborative, creative and secure with Microsoft 365" },
                { id: 3, to: "/category/1", image: "/businesssectionconfig/dynamics.jpg", title: "Dynamics 365", description: "Combine the power of data and intelligence with Microsoft Dynamics 365" },
            ]
        }
    },
    {
        component: "cards", data: {
            caption: "Insights and Highlights",
            pagination:true,
            data:[
                { id: 1, title: "Regulatory Affairs", tags: "Medical Science", image: "/sectionconfig/medical.jpg", desc: 'This Presentation gives an up-to-date overview of the Regulatory Affairs organization and is used for new hire orientation to show how International RA interacts with other RA functions such as Regulatory Compliance.  This presentation is made to all new hires during their first month in Regulatory.', href: "https://www.google.co.uk/", hasicon: true, icon: "" },
                { id: 2, title: "Client stories", tags: "Client stories", image: "/sectionconfig/client stories.jpg", desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio...', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 3, title: "Meet our people", tags: "Meet our people", image: "/sectionconfig/meetourpeople.jpg", desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio...', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 4, title: "Case studies & stories", tags: "Case studies & stories", image: "/sectionconfig/case.jpg", desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio...', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 5, title: "Cloud Journey", tags: "Cloud Journey", image: "/sectionconfig/cloud journey.jpg", desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio...', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 6, title: "my-caption", tags: "Voices of Change", image: "/sectionconfig/voices.jpg", desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio...', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 7, title: "Capabilities", tags: "Capabilities", image: "/sectionconfig/cap.jpg", desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio...', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 8, title: "Our Operations", tags: "What We Do", image: "/sectionconfig/whatwedo.jpg", desc: 'IT product and solutions company for the Utility, Telecom and Public sector', href: "https://saitpoint.com", hasicon: false, icon: "" },
                { id: 9, title: "Application", tags: "Blogs", image: "/sectionconfig/blog.jpg", desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio...', href: "https://saitpoint.com", hasicon: false, icon: "" },
            ]
        }
    },

    {
        component: "blogs", data: [
            {
                id: 1,
                t1: "Tailored Message for Saitpoint Limited",
                t2: "Insights from our experts",
                t3: "Microsoft 365 Solution Architect & Operations Delivery Management",
                t4: "Responsible for managing a company's overall operations. This may include delegating and directing agendas, driving profitability, managing company organizational structure, strategy, and communicating with the board. After spending over 10 years with Saitpoint Limited, I feel very privileged to be writing this message as the Director of Saitpoint Limited. This is a special time in our company's history as we celebrate our 10th anniversary. We began our journey on 21st June 2011 and have spent the past decade building our brand, which has become synonymous with our commitment to helping our clients share knowledge and create innovation. We have seen much change over the past 10 years as a company. It is also important to note what has not changed at Digital Business: our commitments to providing exceptional client service and living our core values: Excellence in Everything, Leadership by Example, Integrity and Transparency, Focus on the Client and Employee Centered. Looking ahead, we are focused on accelerating the execution of our growth strategy while continuing to build on the strength of our brand—in helping our clients share knowledge and create innovation and in bringing positive change to the communities in which we work and live. I am incredibly excited about this journey and honestly believe the best of Digital Business is yet to come.",
                image1: "/businesssectionconfig/ceo.jpg",
                image2: "/businesssectionconfig/h.jpg",
                to: "/page/contact",
                name: "Hari Bondalapati",
                desg: "Operational Manager",
            },
            {
                id: 2,
                t1: "DevOps",
                t2: "Designing DevOps Strategy",
                t3: "Design and implement strategies for collaboration code management, security, compliance, delivery, and monitoring",
                t4: "Implementing DevOps Development Processes, Continuous Integration, Continuous Delivery, Dependency Management, Application Infrastructure and Continuous Feedback.",
                image1: "/businesssectionconfig/devops.jpg",
                image2: "/businesssectionconfig/team2.jpg",
                to: "/page/company",
                name: "DevOps Team",
                desg: "Digital Engagement Team",
            },

        ]
    },
    {
        component: "events", data: [
            // item starts
            {
                component: "slider3", data: [
                    { title: "Celebrations", heading: "10+ years Saitpoiont Limited company celebrations", image: "/businesssectionconfig/Event3.jpg", p: "Purpose in Leadership", date: "11-12-2021" },
                    { title: "Events2", heading: "Connect, Learn, and Grow with us", image: "/businesssectionconfig/Event4.jpg", p: "Purpose in Leadership", date: "17-12-2021" },
                    { title: "Events3", heading: "Connect, Learn, and Grow with us", image: "/businesssectionconfig/Event5.jpg", p: "Purpose in Leadership", date: "22-21-11" },
                    { title: "Events4", heading: "Connect, Learn, and Grow with us", image: "/businesssectionconfig/Event7.jpg", p: "Purpose in Leadership", date: "22-21-11" },
                   
                ]
            },
            // item ends
            // item starts
            {
                component: "slider3", data: [
                    { title: "Saitpoint Webinars", heading: "Learn with our webinar series", image: "/businesssectionconfig/Hadoop.png", p: "Hadoop to Azure Databricks Migration", date: "17-12-2021" },
                    { title: "Saitpoint Event", heading: "Focus Event", image: "/businesssectionconfig/Event2.jpg", p: "Organisation Event", date: "24-21-11" },
                    { title: "Saitpoint Budget", heading: "Annual Report for the Saitpoint", image: "/businesssectionconfig/event8.jpg", p: "Budget Planning", date: "24-21-11" },
            
                   
                ]
            },
            // item ends

        ]
    },
    
    {
        component: "speak", data: {
            title: "Saitpoint Speak",
            backgroundImage: "/businesssectionconfig/speak.jpg",
            video: "logo.png",
            url: "'https://www.w3schools.com/html/mov_bbb.mp4'"
            //https://www.youtube.com/watch?v=fVp88d3BH0k
        }
    },
    {
        component: "partners", data: {
            data: [
                { id: 1, image: "/img/micro.png", },
                { id: 2, image: "/businesssectionconfig/sp.jpg", },
            ]
        }
    },
    {
        component: "ready", data: {
            title: "Ready to begin your digital evolution?",
            label: "Contact Us",
            to: "/page/contact",
        }
    },
]
export default a 