import React from 'react'
import layout from '../layouts'
export default function Single(props) {
    let ref=React.useRef(null)
    // [{"name":"1",data:[]}]
    let urlid=props.match.params.id
    let a=layout["single"].components[1].data
    a=a.filter(x=>+x.id===+urlid)
    
    // if found { id: 1,component:"single", data: [] }
    // alert(urlid)
    
    const load=()=>{
        let str=''
        if(a.length>0)
        {
         str=a[0]?.data
        }
        ref.current.innerHTML=str
    }
    React.useEffect(load,[a])
    if(!a) return <h1 className='guide-no-layout-defined'>undefined post {urlid}</h1>
    else{
       
        return (
            <div className='single------template'  style={{padding: '5%', background: '#f1f1f1', width: '70%'}} ref={ref}>
                loading post for this page...
            </div>
        )
    }
}
