const a={
    name: "company", components: [
        { component: "banner", data: { title: "Saitpoint", description: "Stitching the digital fabric with systems of intelligence    ", image: "https://www.winwire.com/wp-content/uploads/2021/07/Company_Profile@2x-2.jpg" } },
        //  {
        //          component: "banner", data: {
        //       bg: "/businesssectionconfig/speak.jpg"
        //   }
        // },
        { className:"wrapper", component: "h1", data: "Saitpoint Overview",align:"left" },
        { className:"wrapper", component: "p", data:`Saitpoint Technologies is a global technology and consulting company, founded by ex-Microsoft executives, helping enterprises navigate the digital technology revolution. We help drive exponential growth and competitive advantage for our customers through aligning business value and digital transformation. We call it stitching the digital fabric with systems of intelligence.        ` },
        { className:"wrapper", component: "p", data: `We are a Microsoft Managed Partner with deep expertise in digital technologies including Cloud, Data & Artificial Intelligence (AI) / Machine Learning, Mobility, Security, and UI/UX.
        ` },
        { component: "cards4", data: [
            {href:"/",id:1,image:"https://www.winwire.com/wp-content/uploads/2021/07/cloud.png",title:"t1"},
            {href:"/",id:2,image:"https://www.winwire.com/wp-content/uploads/2021/07/cloud.png",title:"t1"},
            {href:"/",id:3,image:"https://www.winwire.com/wp-content/uploads/2021/07/cloud.png",title:"t1"},
            {href:"/",id:4,image:"https://www.winwire.com/wp-content/uploads/2021/07/cloud.png",title:"t1"},
            {href:"/",id:5,image:"https://www.winwire.com/wp-content/uploads/2021/07/cloud.png",title:"t1"},
        ] },

        {className:"wrapper", component: "p", data: `This allows us to work with large and mid-sized enterprises to design, develop, and manage a wide range of intelligent platforms and applications, across industries and business functions.
        ` },
        { component: "speak", data: {backgroundImage:"blue",title:"Our record of leadership in exceptional technology solutions delivery, and ‘People First’ culture makes WinWire your ideal technology service partner."} },
        {className:"wrapper", component: "h1", data: `Why Saitpoint?` },
        {className:"wrapper", component: "p", data:`Clients trust Saitpoint for the right mix of leadership, technology capabilities, and execution excellence. WinWire has enabled various customers across multiple industries, including Healthcare, Retail & Hospitality, Manufacturing & Construction, Financial Services and Hi-tech with solutions addressing HR, Legal, Finance, Operations, Sales & Marketing and IT line-of-business solutions.
        ` },
        {className:"wrapper", component: "p", data: `We are proud of the fact that we build long-term, mutually beneficial relationships with our clients. We have worked with many clients for multiple years and each is a viable reference for our company.
        ` },
        {className:"wrapper", component: "p", data: `In addition to clients, our strong focus on our employees has resulted in a very low attrition rate. The ethic of “people first” is apparent in our fun working environment at our offices.
        
        ` },
        { component: "corporate", },
        {className:"wrapper",align:"center", component: "h1", data: `Why Saitpoint?` },
        { component: "cards4", data: [
            {href:"/",id:1,image:"https://www.winwire.com/wp-content/uploads/2021/07/california-1.png",title:"California"},
            {href:"/",id:2,image:"https://www.winwire.com/wp-content/uploads/2021/07/california-1.png",title:"California"},
            {href:"/",id:3,image:"https://www.winwire.com/wp-content/uploads/2021/07/california-1.png",title:"California"},
            {href:"/",id:4,image:"https://www.winwire.com/wp-content/uploads/2021/07/california-1.png",title:"California"},
            {href:"/",id:5,image:"https://www.winwire.com/wp-content/uploads/2021/07/california-1.png",title:"California"},
        ] },
        
    ]
}
export default a 