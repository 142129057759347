import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import config from './config'
import Top from './components/Top'
import Header from './components/Header'
import Footer from './components/Footer'
export default function App() {
    return (
        <div>
            <BrowserRouter>
                <Top />
                <Header />
                <Switch>
                    {config.routes.map((item, index) => <Route key={index} {...item} />)}
                </Switch>
                <Footer />
            </BrowserRouter>
        </div>
    )
}
