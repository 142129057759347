import * as React from "react"

export const ErrorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37mm"
    height="26mm"
    viewBox="0 0 131.102 92.126"
    {...props}
  >
    <path
      style={{
        opacity: 1,
        fill: "#fff",
        fillOpacity: 0.94509804,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 1.58158517,
        strokeMiterlimit: 4,
        strokeDasharray: "1.58158517,3.16317034",
        strokeDashoffset: 0,
        strokeOpacity: 1,
      }}
      d="M-238.44 979.66v-40.278h49.632l6.23 4.308 6.232 4.308.013 35.97.013 35.969h-62.12v-40.278zm47.667 20.87c.264-.318.418-1.422.35-2.504l-.124-1.935-20.213-.104c-15.905-.082-20.281 0-20.533.4-.494.778-.392 3.693.148 4.233.36.36 5.395.48 20.19.48 16.551 0 19.784-.09 20.182-.57zM-203 992.857c.661-.66.847-3.217.297-4.084-.251-.396-3.428-.484-14.522-.401l-14.2.105-.124 2.167c-.083 1.461.047 2.274.4 2.498.289.183 6.599.335 14.022.339 11.742.01 13.58-.076 14.127-.624zm19.624-8.42c.414-1.079.268-3.59-.238-4.095-.363-.363-6.155-.481-23.612-.481-12.723 0-23.417.11-23.766.243-.466.179-.634.798-.634 2.338 0 1.152.12 2.215.267 2.362.147.147 10.949.267 24.004.267 21.274 0 23.761-.066 23.979-.634zm.163-7.462c.623-.623.623-3.625 0-4.248-.363-.363-6.249-.481-24.032-.481-22.874 0-23.563.022-23.966.775-.228.427-.415 1.25-.415 1.83 0 .58.187 1.404.415 1.83.403.753 1.092.775 23.966.775 17.783 0 23.669-.118 24.032-.48zm.09-8.336c.598-.949.454-3.487-.239-4.18-.547-.546-2.366-.63-13.853-.63-8.348 0-13.509.154-13.998.416-1.065.57-1.12 4.15-.074 4.771.458.272 5.41.384 14.242.32 11.529-.081 13.598-.185 13.921-.697zm-.087-9.58c.083-1.462-.047-2.275-.4-2.5-.885-.56-39.755-.429-40.321.137-.625.625-.622 3.626.004 4.252.369.369 5.275.46 20.54.38l20.054-.103.124-2.166z"
      transform="translate(0 -960.236)"
    />
    <path
      style={{
        fill: "none",
        stroke: "#fff",
        strokeWidth: 10,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M6.943 29.833h47.433M17.615 20.907 5.624 29.521l11.704 9.572"
      transform="translate(22.233 4.76) scale(1.37036)"
    />
    <path
      d="M33.421 993.044c24.192-30.341 53.57-20.974 65 0"
      style={{
        fill: "none",
        stroke: "red",
        strokeWidth: 6.85180616,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
      }}
      transform="translate(0 -960.236)"
    />
    <path
      d="m33.982 977.564-5.797 19.385 20.712.583"
      style={{
        fill: "none",
        stroke: "red",
        strokeWidth: 6.85180902,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="translate(0 -960.236)"
    />
    <path
      d="M94.254 1014.933c-24.192 30.342-53.57 20.975-65 0"
      style={{
        fill: "none",
        stroke: "red",
        strokeWidth: 6.85180616,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
      }}
      transform="translate(0 -960.236)"
    />
    <path
      d="m93.693 1030.413 5.797-19.385-20.712-.583"
      style={{
        fill: "none",
        stroke: "red",
        strokeWidth: 6.85180902,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="translate(0 -960.236)"
    />
    <text
      xmlSpace="preserve"
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 40,
        lineHeight: "125%",
        fontFamily: "sans-serif",
        letterSpacing: 0,
        wordSpacing: 0,
        fill: "red",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 1,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      x={55.966}
      y={1018.53}
      transform="translate(0 -960.236)"
    >
      <tspan x={55.966} y={1018.53}>
        {"!"}
      </tspan>
    </text>
  </svg>
)

