const a= {
    name: "notifications", components: [
        { 
            component: "banner", data: { title: "Notifications", description: "All notifications from blogs   ", image: "/img/noti.jpg" } 
        },
        // {
        //     component: "banner", data: {
        //         bg: "/businesssectionconfig/speak.jpg"
        //     }
        // },
        { component: "cards5", data: [
            {href:"/",id:1,image:"/img/notification.jpg",title:"t1"},
            {href:"/",id:2,image:"https://www.winwire.com/wp-content/uploads/2021/07/cloud.png",title:"t1"},
            {href:"/",id:3,image:"https://www.winwire.com/wp-content/uploads/2021/07/cloud.png",title:"t1"},
            {href:"/",id:4,image:"https://www.winwire.com/wp-content/uploads/2021/07/cloud.png",title:"t1"},
            {href:"/",id:5,image:"https://www.winwire.com/wp-content/uploads/2021/07/cloud.png",title:"t1"},
        ] },
        {component:"partners",data:{pagination:false,data:[
            { id: 1, image: "/img/micro.png", },
            { id: 2, image: "/businesssectionconfig/sp.jpg", },
        ]}},
        
    ]
}
export default a 