import React from 'react'

export default function banner({data}) {
    return (
        <div style={{backgroundImage:`url('${data.image}')`}} className='banner-top'>
            <h1>{data.title}</h1>
            <h3>{data.description}</h3>
        </div>
    )
}
