const a= {name:"ourcompany",components:[
    {component:"banner",data:{
        title:"Saitpoint Limited",
        description:"Connecting Business Need with IT",
        image:"/img/information.jpg"
    }
 },
    {
        component:"cards3",
        data:{pagination:false,
        title:"Saitpoint Limited",
        description:"Connecting Business Need with IT",
        data:[
        { id: 1, title: "About Saitpoint Limited", tags: "Medical Science", image: "/img/about.jpg", desc:'Saitpoint is most prestigious in consulting, digital transformation, technology and engineering services. The Group is at the forefront of innovation to address the entire breadth of clients opportunities in the evolving world of cloud, digital and platforms. Saitpoint provides cutting edge software development and implementation solutions to enterprises across several industries internationally. Founded in London in 2011, we have significant expertise and know-how in application development, software implementation, business intelligence, data analytics, infrastructure and digital transformation.', link: "/", hasicon: false, icon: "" },
        { id: 2, title: "Our purpose", tags: "Client stories", image: "/img/purpose.jpg", desc: 'Unleashing human energy through technology for an inclusive and sustainable future for the Saitpoint Group, technology promises progress. Our purpose was developed in collaboration with the Group’s many stakeholders, and with its employees at the heart of the process. It now forms one of the fundamentals of the Group.', link: "/", hasicon: false, icon: "" },
        { id: 3, title: "Company Profile & Key Figures", tags: "Meet our people", image: "/img/Profile.jpg", desc: 'Saitpoint is a responsible and diverse organization that has a number of team members in nearly 5 countries. With its strong 10 year heritage and deep industry expertise, Saitpoint is trusted by its clients to address the entire breadth of their business needs, from strategy and design to operations, fueled by the fast-evolving and innovative world of cloud, data, AI, connectivity, software, digital engineering and platforms', link: "/", hasicon: false, icon: "" },
      //  { id: 4, title: "my-caption", tags: "Case studies & stories", image: "/sectionconfig/case.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
    //     { id: 5, title: "my-caption", tags: "Cloud Journey", image: "/sectionconfig/cloud journey.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
    //     { id: 6, title: "my-caption", tags: "Voices of Change", image: "/sectionconfig/voices.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
    //     { id: 7, title: "my-caption", tags: "Capabilities", image: "/sectionconfig/cap.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
    //     { id: 8, title: "my-caption", tags: "What We Do", image: "/sectionconfig/whatwedo.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
    //     { id: 9, title: "my-caption", tags: "Blogs", image: "/sectionconfig/blog.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
]}},
    {component:"partners",data:{pagination:false,data:[
        { id: 1, image: "/img/micro.png", },
        { id: 2, image: "/businesssectionconfig/sp.jpg", },
    ]}},

]}
export default a 