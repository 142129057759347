import React, { useState } from 'react';
import { usePagination } from '../utils/hooks'
export default function Cards(props) {
    let data = props.data
    const [start, setstart] = useState(0)
    const pp=6

    let pagination = usePagination(pp, data.length)
    const handleClick = num => setstart(num)
    if (props.pagination) {
        return (
            <div className='blogcards-container  wrapper'>
                <div className='wrapper' align="center">  <h1 className='h1'>{props.title}</h1></div>
                <div className='wrapper' align="center">  <h2 className='h2'>{props.description}</h2></div>
                <div className='cards'>
                    {data.slice(start, start + pp).map((x, i) => <RenderItem key={i} x={x} />)}
                </div>
                <div className='pagination'>
                    {pagination.map(x => <button onClick={e => handleClick(x * pp)} className={start === x * pp ? 'pagination-item active' : 'pagination-item'}>{x + 1}</button>)}
                </div>
            </div>
        );
    }
    else {
        return (
            <div className='blogcards-container  wrapper'>
                <div className='wrapper' align="center">  <h1 className='h1'>{props.title}</h1></div>
                <div className='wrapper' align="center">  <h2 className='h2'>{props.description}</h2></div>
                <div className='cards'>
                    {data.map((x, i) => <RenderItem key={i} x={x} />)}
                </div>
            </div>
        );
    }
}

const RenderItem = ({ x }) => {
    // const f1 = dt => new Date(dt).toDateString().toString().split(" ").join("/")
    const f1 = dt => new Date(dt).toDateString()
    return <div className='item'>
        <img alt="best in country saitpoint.com"  src={x.image} className='big-image' />
        <div className='pad'>
            <div className='date-flex'>
                <div>{f1(x.datepublished)}</div>
                <div>{x.time} read</div>
            </div>
            <h3>{x.name}</h3>
            <div className='auth-flex'>
                <img alt="best in country saitpoint.com"  src={x.authorpic} />
                <div>
                    <strong>{x.author}</strong>
                    <br />
                    <small>{x.position}</small>
                </div>
            </div>
            <div className='share-flex'>
                <div><i style={{ color: "red" }} className='fa fa-eye'></i> {x.views}</div>
                <div><i className='fa fa-share-alt share-icon'></i></div>
            </div>
        </div>
    </div>
}