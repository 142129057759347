import React from 'react'
import Layout from '../components/Layout'
import layout from '../layouts'
let name="category"
export default function Category(props) {
    let {cat_name}=props.match.params
    // let a=layout[name][cat_name]
    let a=layout[name].find(x=>x.name===cat_name)
    if(!a) return <h1 className='guide-no-layout-defined'>undefined layout [{name}]-[{cat_name}]</h1>
    return (
        <div>
            {a.layout.map((layoutTemplate,index)=>{
                return <Layout {...layoutTemplate} />
            })}
        </div>
    )
}
