import p1 from './posts/1'
import p2 from './posts/2'
const singlelayout={
    name: "single", components: [
        {
            component: "banner", data: {
                title: "Learning and Recognitions                ",
                description: "Company accolades                ",
                image: "https://www.winwire.com/wp-content/uploads/2018/02/Awards.png"
            }
        },
        {
            name: "post", data: [
                { id: 1,component:"single", data: p1 },
                { id: 2,component:"single", data: p2 },
                { id: 3,component:"single", data: [] },
                { id: 4,component:"single", data: [] },
                { id: 5,component:"single", data: [] },
                { id: 6,component:"single", data: [] },
            ]
        },
        {
            component: "partners", data: {
                pagination: false, data: [
                    { id: 1, image: "/img/micro.png", },
                    { id: 2, image: "/businesssectionconfig/sp.jpg", },
                ]
            }
        },
        
    ]
}
export default  singlelayout