const footer=[
    { heading: "Services", link: "/", children: 
    [    
         {title:"Artificial Intelligence",link:"https://www.microsoft.com/en-gb/"},
         {title:"Technology Solutions",link:"https://www.microsoft.com/en-gb/"},
         {title:"Research & Development",link:"https://www.microsoft.com/en-gb/"},
         {title:"Cloud Servies",link:"https://www.microsoft.com/en-gb/"},
         {title:"Cybersecurity Services",link:"https://www.microsoft.com/en-gb/"},
         {title:"Business Operations",link:"https://www.microsoft.com/en-gb/"},
         {title:"Integration Services",link:"https://www.microsoft.com/en-gb/"},
         {title:"Digital Service",link:"https://www.microsoft.com/en-gb/"},
         {title:"All Services",link:"https://www.microsoft.com/en-gb/"},

        ] 
    },
    { heading: "Medicine Science", link: "/", children: [
        {title:"Regulatory Affairs",link:"/Regulatory Affairs"},
        {title:"International RA",link:"International RA"},
        {title:"Regulatory Operations",link:"Regulatory Operations"},
        {title:"Doc Processing",link:"Doc Processing"},
        {title:"Ad Promo",link:"Ad Promo"},
        {title:"Labeling",link:"Labeling"},
        {title:"Compliance",link:"Compliance"},
        {title:"CMC",link:"CMC"},
    ] },
    { heading: "Expertise", link: "/", children: 
     [
        { title: "Azure", link: "https://azure.microsoft.com/" }, 
        { title: "Artificial Intelligence", link: "" }, 
        { title: "Microsoft 365", link: "https://www.microsoft.com/en/microsoft-365" }, 
        { title: "Integration Services", link: "" }, 
        { title: "Application Modernization", link: "" }, 
        { title: "Dynamics 365", link: "https://dynamics.microsoft.com/" }, 
        { title: "Managed Services", link: "" },
        { title: "Testing Services", link: "" },

    ]
  },
  { heading: "Industries", link: "/", children: 
     [ 
        { title: "Healthcare", link: "" }, 
        { title: "Medical Science", link: "" }, 
        { title: "Public Sector", link: "" }, 
        { title: "Telecoms", link: "" }, 
        { title: "Energy and Utilities", link: "" }, 
        { title: "Banking", link: "" },
        { title: "Electronics", link: "" },
        { title: "Insurance", link: "" },
        { title: "All Industries", link: "" },   
    ] },
  { heading: "Partners ", link: "/", children: 
    [
        { title: "Microsoft", link: "https://www.microsoft.com/" }, 
        { title: "Amazon Web Services", link: "" }, 
        { title: "Google", link: "" }, 
        { title: "All our partners", link: "" }, 
    ]     
},
    { heading: "About us", link: "/page/ourcompany", children: 
    [
       { title: "Our Company", link: "/page/ourcompany" }, 
      { title: "Our Offices", link: "/page/contact" }, 
      { title: "Our Purpose", link: "/page/ourcompany" }, 
      { title: "Contact us", link: "/page/contact" }
        
    ]  
   },
]
export default footer