import React  from 'react';

export default function Partners(props) {
    let {data}=props.data
    return (
        <div className='partners wrapper'>
            {data.map(x=><div key={x.image} className='item'>
                <img alt="best in country saitpoint.com"  src={x.image} />
            </div>)}
        </div>
    );
}