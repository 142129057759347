const a= [
    {
        component: "tabs", data: {
            title: "International Regulatory Affairs",
            p: "International RA is responsible for monitoring the regulatory environment to help Saitpoint make sound strategic decisions in order to prepare and progress submissions to regulatory authorities in International territories for the whole product lifecycle, from clinical trials through to marketing authorization applications and post-approval changes such as new therapeutic indications.",
            tabscontent: [
                { id: 1, name: "Regulatory Affairs", elements: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio.", "tab1 second"] },
                { id: 2, name: "Doc Processing", elements: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio.", "tab2 second"] },
                { id: 3, name: "Ad Promo", elements: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio.", "tab3 second"] },
                { id: 4, name: "Labeling", elements: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio.", "tab4 second"] },
                { id: 5, name: "Compliance", elements: ["Audit Management, Clinical Compliance, Electronic Systems Compliance(ESC), Quality Documents & Training, Administration(QDTA)", 
                "Audit Management: Audit Management, a sub-function of Regulatory Compliance (RC), supports RC activities by providing audit response coordination, management of audit and inspection-related information and records, audit and inspection metrics, contracts and requisitions for consultant auditors, and administration of the GTrack application for RC Audit.",

                "tab5 second"] },
                { id: 6, name: "CMC", elements: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus placerat malesuada odio.", "tab6 second"] },
            ]
        }
    },
    {
        component: "partners", data: {
            data: [
                { id: 1, image: "https://www.winwire.com/wp-content/uploads/2017/12/micro.png", },
                { id: 2, image: "/businesssectionconfig/sp.jpg", },
            ]
        }
    },
]


export default a 