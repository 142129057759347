// import Slider1 from './slider1'
// import design1 from './design1'
// import design2 from './design2'
// import slider2 from './slider2'
// import design3 from './design3'
// import design4 from './design4'
// import design5 from './design5'
// import design6 from './design6'
import AppError from './app-error'
import React from 'react'
function Layout(props) {
    try {

        let Root = require("./" + props.component).default
        return <AppError><Root data={props.data} {...props} /> </AppError>
    } catch (e) {
        return <h1 className='error' style={{color:"red"}}>({props.component?props.component:"provide name of component"} + " not found please create")</h1>
    }
}

export default Layout