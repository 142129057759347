import React from 'react'
import Layout from '../components/Layout'
import layout from '../layouts'
let name="tags"
export default function Tags() {
    let a=layout[name]
    if(!a) return <h1 className='guide-no-layout-defined'>undefined layout [{name}]</h1>
    return (
        <div>
            {a.map((layoutTemplate,index)=>{
                return <Layout {...layoutTemplate} />
            })}
        </div>
    )
}
