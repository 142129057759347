import React  from 'react';
export default function Cards4(props) {
    let data = props
    return (
        <div className='wrapper'>
            <div className='cards4'>
                {data.data.map((x, i) => <div key={i} className='item'>
                    <img alt="best in country saitpoint.com"  src={x.image}   />
                    <div className='pad'>
                        <h3>{x.title}</h3>
                        {/* <a className='read-more' target="_blank" href={x.href}>read more2</a> */}
                    </div>
                </div>)}
            </div>
        </div>
    );
}