import React  from 'react';
export default function Tabs(props) {
    let { title, p, tabscontent } = props.data
    const [state, setstate] = React.useState(tabscontent[0])
    return (
        <div className='tabs wrapper'>
            <div className='tabs-area'>
                <h2>{title}</h2>
                <p>{p}</p>
            </div>
            <div className='tab-container'>
                <div className='tab-h-flex'>
                    {tabscontent.map(x => <div onClick={e=>setstate(x)} key={x.name} className={state?.id===x.id?'tab-header active':'tab-header'}>{x.name}</div>)}
                </div>
                <div className='tab-body'>
                    {state?.elements?.map((x, i) => <p key={i}>{x}</p>)}
                    {/* {JSON.stringify(state)} */}
                </div>
            </div>
        </div>
    );
}