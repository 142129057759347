const a= [
    {
        component: "cards3", data: {
            caption: "Cloud Servies",
            pagination:true,
            data:[
                { id: 1, title: "Cloud Services", tags: "Cloud Services", image: "/img/cloud.jpg", desc: 'In the age of disruption, the cloud is the foundation for digital agility, changing the way we live and work, enabling us to do more with less, and accelerating the pace of human innovation. Saitpoint helps you harness the cloud to create delightful experiences, open new avenues of collaboration, and optimize your value chain to generate profit and growth. Implement and monitor an Azure infrastructure, management and security solutions, solutions for apps and manage data platforms', link: "https://www.google.co.uk/", hasicon: true, icon: "" },
                { id: 2, title: "Client stories", tags: "Client stories", image: "/sectionconfig/client stories.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 3, title: "Meet our people", tags: "Meet our people", image: "/sectionconfig/meetourpeople.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 4, title: "Case studies & stories", tags: "Case studies & stories", image: "/sectionconfig/case.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 5, title: "Cloud Journey", tags: "Cloud Journey", image: "/sectionconfig/cloud journey.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 6, title: "my-caption", tags: "Voices of Change", image: "/sectionconfig/voices.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 7, title: "Capabilities", tags: "Capabilities", image: "/sectionconfig/cap.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
                { id: 8, title: "Our Operations", tags: "What We Do", image: "/sectionconfig/whatwedo.jpg", desc: 'IT product and solutions company for the Utility, Telecom and Public sector', link: "/", hasicon: false, icon: "" },
                { id: 9, title: "Application", tags: "Blogs", image: "/sectionconfig/blog.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
            ]
        }
    },
    {
        component: "partners", data: {
            data: [
                { id: 1, image: "https://www.winwire.com/wp-content/uploads/2017/12/micro.png", },
                { id: 2, image: "/businesssectionconfig/sp.jpg", },
            ]
        }
    },
    ]


        export default a 