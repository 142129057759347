import Category from '../pages/category'
import Custom from '../pages/custom'
import Home from '../pages/Home'
import Page from '../pages/page'
import Pages from '../pages/pages'
import Posts from '../pages/posts'
import Search from '../pages/search'
import Single from '../pages/single'
import Tags from '../pages/tags'
const routes = [
    { path: ["/", "/Home"], component: Home, exact: true },
    { path: "/page/:pagename", component: Page, exact: true },
    { path: "/pages/:pagecategory", component: Pages, exact: true },
    { path: "/custom/:templatename", component: Custom, exact: true },
    { path: "/category/:cat_name", component: Category, exact: true },
    { path: "/tags/:tagname", component: Tags, exact: true },
    { path: "/search/:query", component: Search, exact: true },
    { path: "/single/:template/:x", component: Single, exact: true },
    { path: "/blogs", component: Posts, exact: true },
    { path: "/blogs/:id", component: Single, exact: true },
    { component: Home },
]
export default routes