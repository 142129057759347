import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
// import 'react-slick/dist/react-slick/css'
export default function Slider2({ data }) {
    var settings = {
        dots: true,
        infinite: true,
        speed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
    };
    return (
        <div className="blogs  wrapper">
            <Slider {...settings} >
                {data.map((x, index) => <div className="slider2-container">
                    <h2>{x.t1}</h2>
                    <h3>{x.t2}</h3>
                    <div className="inner-flex">
                        <div className="item-img">
                            <img alt="best in country saitpoint.com"  src={x.image1} />
                        </div>
                        <div className="item-text">
                            <h4>{x.t3}</h4>
                            <details>{x.t4}</details>
                            <div className="avatar">
                                <img alt="best in country saitpoint.com"  src={x.image2} />
                                <div className="right">
                                    <h4>{x.name}</h4>
                                    <small>{x.desg}</small>
                                </div>
                            </div>
                            <Link to={x.to}>Read More</Link>
                        </div>
                    </div>
                </div>
                )}
            </Slider>
        </div>
    );
}