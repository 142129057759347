const a= {name:"Jobs",components:[
    {component:"banner",data:{
        title:"find jobs here                ",
        description:"React dotnet java full stack jobs are posted here",
        image:"https://www.winwire.com/wp-content/uploads/2018/02/Awards.png"
    }},
    {component:"cards6",data:{pagination:true,title:"job title",desc:"job tye desc",data:[
        { id: 1, title: "my-caption", tags: "job category1", image: "/sectionconfig/medical.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        { id: 2, title: "my-caption", tags: "job category1", image: "/sectionconfig/client stories.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        { id: 3, title: "my-caption", tags: "job category1", image: "/sectionconfig/meetourpeople.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        { id: 4, title: "my-caption", tags: "job category1", image: "/sectionconfig/case.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        { id: 5, title: "my-caption", tags: "job category1", image: "/sectionconfig/cloud journey.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        { id: 6, title: "my-caption", tags: "job category1", image: "/sectionconfig/voices.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        { id: 7, title: "my-caption", tags: "job category1", image:"/sectionconfig/cap.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        { id: 8, title: "my-caption", tags: "job category1", image: "/sectionconfig/whatwedo.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
        { id: 9, title: "my-caption", tags: "job category1", image: "/sectionconfig/blog.jpg", desc: 'A manufacturer of vitamins and nutritional supplements uses Azure Synapse Analytics to improve customer satisfaction', link: "/", hasicon: false, icon: "" },
    ]}},
    {component:"partners",data:{pagination:false,data:[
        { id: 1, image: "/img/micro.png", },
        { id: 2, image: "/businesssectionconfig/sp.jpg", },
    ]}},

]}
export default a 