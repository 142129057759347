const bloglayouts=[
    // {
    //     component: "banner", data: {
    //         title: "Learning and Recognitions                ",
    //         description: "Company accolades                ",
    //         image: "https://www.winwire.com/wp-content/uploads/2018/02/Awards.png"
    //     }
    // },
    {
        component: "blogcards",
        title: "Everything You Need To Know About",
        description: "Trending Topics",
        searchbar: "",
        filters: "",
        pagination: true,
        data: [
            { id: 1, name: "From Being Fragile to Adopting Pragmatic Agile Practices", image: "/img/blog.jpg", time: "2 mins", author: "Manohar Pattem", position: "Data Scientist", link: "/blogs/1", category: "p1", authorpic: "/businesssectionconfig/h.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
            { id: 3, name: "Performance, integrity and security of a database", image: "/img/db.jpg", time: "2 mins", author: "Srinivasarao Balla", position: "DBA Engineer", link: "/blogs/3", category: "p3", authorpic: "/img/user2.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
            { id: 4, name: "Analyzing and translating business information and technical requirements into an architectural blueprint", image: "/img/sap.jpg", time: "5 mins", author: "Balu Panathula", position: "SAP Architect", link: "/blogs/4", category: "p4", authorpic: "/img/user3.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
            { id: 4, name: "Create or implement AI powered services like chatbots - Research and solve business problems using Natural Language Processing-NLP", image: "/img/ai1.jpg", time: "2 mins", author: "Laxman", position: "AI Specialist", link: "/blogs/4", category: "p4", authorpic: "/img/user1.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
            { id: 4, name: "Cloud Architect for SAP Solutions", image: "/img/cloudarchi.jpg", time: "2 mins", author: "Krishna Madireddy", position: "SAP Cloud Engineer", link: "/blogs/4", category: "p4", authorpic: "/businesssectionconfig/h.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
            { id: 4, name: "Boosting the competencies of our employees by developing and conducting effective training programs", image: "/img/training.jpg", time: "10 mins", author: "B S Rao", position: "Training Specialist", link: "/blogs/4", category: "p4", authorpic: "/businesssectionconfig/h.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
            { id: 4, name: "Business Analysts analyse data and use their findings from data analytics to solve business problems", image: "/img/blog.jpg", time: "2 mins", author: "Poojita Bondalapati", position: "Business Analyst", link: "/blogs/4", category: "p4", authorpic: "/img/poojita.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
            { id: 4, name: "Data to propose solutions for effective decision making, build algorithms and design experiments to merge, manage, interrogate and extract data to supply tailored reports to colleagues", image: "/img/blog.jpg", time: "2 mins", author: "Sai Bondalapati", position: "Data Science", link: "/blogs/4", category: "p4", authorpic: "/businesssectionconfig/h.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
            { id: 4, name: "Responsible for analyzing the Saitpoint Business market and investment opportunities for the company they work for", image: "/img/blog.jpg", time: "2 mins", author: "Anu Bondalapati", position: "Technical Analyst", link: "/blogs/4", category: "p4", authorpic: "/img/anu.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
            { id: 5, name: "Implementation and Delivery of Microsoft Application projects", image: "/img/blog.jpg", time: "2 mins", author: "Nagaraju Ganiger", position: "Microsoft Architect", link: "/blogs/5", category: "p5", authorpic: "/businesssectionconfig/h.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
            { id: 6, name: "Conversational UX Design – An Introduction", image: "/img/blog.jpg", time: "2 mins", author: "Gopi Balla", position: "UI/UX Designer", link: "/blogs/6", category: "p6", authorpic: "/businesssectionconfig/h.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
            { id: 7, name: " ", image: "/img/blog.jpg", time: "2 mins", author: "Arjunarao", position: "Agricultural", link: "/blogs/7", category: "Agricultural", authorpic: "/businesssectionconfig/h.jpg", datepublished: Date.now(), views: 2, share: ["facebook"] },
        ]
    }, {
        component: "partners", data: {
            data: [
                { id: 1, image: "/img/micro.png", },
                { id: 2, image: "/businesssectionconfig/sp.jpg", },
            ]
        }
    },
]
export default bloglayouts