import React  from 'react';
export default function Cards5(props) {
    let data = props
    return (
        <div className='wrapper'>
            <div className='cards5'>
                {data.data.map((x, i) => <div key={i} className='item'>
                    <img alt="best in country saitpoint.com"  src={x.image}   />
                    <div className='pad'>
                        <h3>{x.title}</h3>
                        <h2>latest from notifications for you on : { new Date().toString().slice(0,16)}</h2>
                        <h4>posted on  : { new Date().toString().slice(16,33)}</h4>
                        <a rel="noreferrer" className='read-more' target="_blank" href={x.href}>read more</a>
                    </div>
                </div>)}
            </div>
        </div>
    );
}