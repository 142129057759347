import pages from './pages'
import routes from './routes'
import header from './header'
import footer from './footer'
import bottom from './bottom'
const config={
    pages,
    routes,
    header,
    footer,
    bottom
}

export default config