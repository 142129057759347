import React  from 'react';

import { Link } from 'react-router-dom';
export default function Services(props) {
    let { title, data } = props.data
    return (
        <div className='services wrapper'>
            <h1 className='h1'>{title}</h1>
            <div className='flex'>
                {data.map((x,i)=><div key={i} className='item'>
                    <img alt="best in country saitpoint.com"  src={x.image} height={141} />
                    <br/>
                    <br/>
                    <Link className="read-more" to={x.href} >{x.title}</Link>
                </div>)}
            </div>
        </div>
    );
}