import React from 'react'
import {ErrorIcon} from './icons'
export default class AppError extends React.Component {
    state = { status: false }

    componentDidCatch(error)
    {
        this.setState({status:true})
    }

    render() {

        if (this.state.status) {
            return <div className='error-boundry'>
                <ErrorIcon/>
            </div>
        }
        else {
            return this.props.children
        }
    }
}